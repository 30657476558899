import React from 'react'
import PropTypes from 'prop-types'
import {isEmpty} from 'lodash-es'
import {If, For} from 'babel-plugin-jsx-control-statements'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import PrimaryButton from '../../atoms/PrimaryButton'
import ReferenceCard from '../../atoms/ReferenceCard'
import Tag from '../../containers/Tags'
import ReferenceList from '../ReferenceList'


const StyledSectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 6.125rem;
  padding-left: 12.5rem;
  width: 100%;
  height: auto;

  ${media.down('mobile')(css`
    flex-direction: column;
    padding-left: 0;
    margin-top: 4.375rem;
  `)}
`

const StyledLogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  margin-bottom: 3.125rem;
  padding-right: 3rem;
  width: 80%;

  ${media.down('mobile')(css`
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    width: 100%;
  `)}
`

const StyledLogoContainer = styled.div`
  margin-right: 6rem;
  margin-bottom: 3rem;
  max-width: 8rem;
  height: auto;

  ${media.down('mobile')(css`
    display: flex;
    justify-content: center;
    margin: 0 0 3rem 0;
    min-width: 50%;
  `)}
`

const StyledLogo = styled.img`
  opacity: 0.1;
  width: 100%;
  max-width: 8rem;
  height: 100%;
  filter: grayscale(100%);
`

const StyledButtonContainer = styled.div`
  width: 20%;

  ${media.down('mobile')(css`
    display: flex;
    justify-content: center;
    width: 100%;
    order: 2;
    margin-top: 4.375rem;
    margin-bottom: 1.875rem;
    text-align: center;
  `)}
`

const StyledReferenceList = styled(ReferenceList)`
  z-index: 100;
  margin-bottom: -5.25rem;
  width: 100%;

  ${media.down('mobile')(css`
    margin-bottom: 0;
  `)}
`

const ReferenceRelation = ({logos, allReferencesText, allReferencesLink, references, lang, ...props}) => {
  return (
    <StyledSectionContainer {...props} >
      <StyledLogosContainer>
        <If condition={!isEmpty(logos)}>
          <For each="logo" of={logos} index="idx">
            <StyledLogoContainer key={idx}>
              <StyledLogo src={logo.logo.publicURL} alt={logo.alt} />
            </StyledLogoContainer>
          </For>
        </If>
      </StyledLogosContainer>
      <StyledButtonContainer>
        <PrimaryButton to={allReferencesLink}>
          {allReferencesText}
        </PrimaryButton>
      </StyledButtonContainer>
      <StyledReferenceList>
        <For each="reference" of={references} index="idx">
          <ReferenceCard
              key={idx}
              to={reference.slug}
              image={reference.relation.relationImage.image}
              alt={reference.relation.relationImage.alt}
              title={reference.hero.title}
              subtitle={reference.relation.shortDescription}
          >
            <For each="tag" of={reference.hero.keyServices.services} index="idx">
              <Tag
                  key={idx}
                  tagKey={tag.keyService}
                  lang={lang}
              />
            </For>
          </ReferenceCard>
        </For>
      </StyledReferenceList>
    </StyledSectionContainer>
  )
}

ReferenceRelation.propTypes = {
  logos: PropTypes.array.isRequired,
  allReferencesText: PropTypes.string.isRequired,
  allReferencesLink: PropTypes.string.isRequired,
  references: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
}

export default ReferenceRelation
