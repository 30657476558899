import PropTypes from 'prop-types'
import styled from 'styled-components'
import {TransitionGroup, CSSTransition} from 'react-transition-group'


const StyledTransitions = styled.div`
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 250ms ease-in;
    opacity: 1;
  }

  .fade-exit {
    display: none;
  }
`

const Transition = ({transitionKey, children}) => {
  return (
    <TransitionGroup component={StyledTransitions}>
      <CSSTransition
          key={transitionKey}
          timeout={500}
          classNames="fade"
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  )
}

Transition.propTypes = {
  transitionKey: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

export default Transition
