import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledSection = styled.section`
  padding: 3rem 0;
`

const Section = ({children, ...props}) => (
  <StyledSection {...props}>
    {children}
  </StyledSection>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
